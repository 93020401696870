<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 30px 15px 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

  .inner-title {
    position: absolute;
    top: -4px;
    left: 12px;
    // background: #fff;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }

  .inner-btn {
    padding-left: 10px;
  }

  button {
    margin-right: 0.5rem;
  }

  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
}

.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}

.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}

.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}

.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}

.item-column {
  display: flex;
  flex-direction: column;
}

.button-panel {
  width: 100%;
  bottom: 0;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-around;
}

.icon-finished::before {
  content: url('../../../../assets/img/finished.png');
}

.icon-warn::before {
  content: url('../../../../assets/img/warn.png');
}

.surgery-information {
  margin-top: 20px;
  margin-bottom: 20px;

  .equipment_style {
    /deep/.el-input__inner {
      border: 0;
      padding: 0;
      text-align: center;
    }

    /deep/.el-input__inner {
      border: 1px solid black;
    }
  }

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 20%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    text-align: center;
    width: 120px;
  }
}
</style>
<style lang="less">
.surgery-information {
  .el-tabs__content {
    padding: 0;
  }
}
</style>
<template>
  <div class="info-table">
    <span class="inner-title">血管信息{{ isReadOnly ? '' : '录入' }}</span>
    <div class="inner-btn">
      <el-button
        size="mini"
        type="primary"
        class="commonBtn"
        @click="_insert"
        v-if="!isReadOnly"
        :disabled="isReadOnly"
      >
        血管新增
      </el-button>
      <el-button
        size="mini"
        type="warning"
        class="commonBtn"
        @click="_modify"
        v-if="!isReadOnly"
        :disabled="isReadOnly"
      >
        血管修改
      </el-button>
      <el-button
        size="mini"
        type="danger"
        class="commonBtn"
        @click="open_delete"
        v-if="!isReadOnly"
        :disabled="isReadOnly"
      >
        血管删除
      </el-button>
    </div>
    <el-table
      class="inner-table"
      :data="tableData"
      highlight-current-row
      :header-cell-style="{ padding: 0 }"
      @row-click="_rowClick"
      @row-dblclick="_doubleModify"
      height="200px"
      style="width: 99%"
      border
    >
      <el-table-column prop="lesionRegion" label="病变部位" align="center"></el-table-column>
      <el-table-column prop="degreeValue" label="狭窄程度(%)" align="center"></el-table-column>
      <el-table-column prop="lengthValue" label="病变长度(mm)" align="center"></el-table-column>
      <el-table-column prop="lesionFeature" label="病变特点" align="center"></el-table-column>
      <el-table-column prop="timi" label="TIMI血流" align="center"></el-table-column>
      <el-table-column prop="lesionKind" label="病变分型" align="center"></el-table-column>
      <el-table-column
        prop="interventionHistory"
        label="既往是否介入治疗"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stentingHistory"
        label="既往弹簧圈/支架植入情况"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stentingType"
        label="既往弹簧圈/支架类型"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="handlingSuggestions"
        label="本次处理意见"
        align="center"
      ></el-table-column>
    </el-table>

    <div class="surgery-information">
      <el-tabs v-model="tabs_index" @tab-click="handleClick" type="border-card">
        <el-tab-pane :key="key_list[0]">
          <span slot="label">介入耗材</span>
          <div class="equipment_style">
            <el-card>
              <el-table
                :header-cell-style="{ padding: 0 }"
                height="300"
                style="width: 100%"
                border
                :data="equipment_list"
                :highlight-current-row="true"
              >
                <el-table-column
                  prop="lesionRegion"
                  label="病变部位"
                  align="center"
                  width="150"
                ></el-table-column>
                <el-table-column
                  prop="equipmentName"
                  label="耗材全称"
                  align="center"
                  width="300"
                ></el-table-column>
                <el-table-column
                  prop="minPressure"
                  label="最小压力（atm）"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    <div v-if="showList[scope.$index].isShow === true">
                      <cqt-input
                        :isReadonly="isReadOnly"
                        placeholder=""
                        v-model="scope.row.minPressure"
                      />
                    </div>
                    <div v-else>
                      {{ scope.row.minPressure }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="maxPressure"
                  label="最大压力（atm）"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    <div v-if="showList[scope.$index].isShow === true">
                      <cqt-input
                        :isReadonly="isReadOnly"
                        placeholder=""
                        v-model="scope.row.maxPressure"
                      />
                    </div>
                    <div v-else>
                      {{ scope.row.maxPressure }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="releaseTime"
                  label="释放时间（s）"
                  align="center"
                  width="160"
                >
                  <template slot-scope="scope">
                    <div v-if="showList[scope.$index].isShow === true">
                      <cqt-input
                        :isReadonly="isReadOnly"
                        placeholder=""
                        v-model="scope.row.releaseTime"
                      />
                    </div>
                    <div v-else>
                      {{ scope.row.releaseTime }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="num" label="使用数量" align="center" width="100">
                  <template slot-scope="scope">
                    <div v-if="showList[scope.$index].isShow === true">
                      <cqt-input :isReadonly="isReadOnly" v-model="scope.row.num" />
                    </div>
                    <div v-else>
                      {{ scope.row.num }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="useSuccess" label="是否使用成功" align="center" width="100">
                  <template slot-scope="scope">
                    <div v-if="showList[scope.$index].isShow === true">
                      <cqt-input :isReadonly="isReadOnly" v-model="scope.row.useSuccess" />
                    </div>
                    <div v-else>
                      {{ scope.row.useSuccess }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="note" label="备注" align="center" min-width="300">
                  <template slot-scope="scope">
                    <div v-if="showList[scope.$index].isShow === true">
                      <cqt-input
                        :isReadonly="isReadOnly"
                        placeholder=""
                        size="mini"
                        v-model="scope.row.note"
                      />
                    </div>
                    <div v-else>
                      {{ scope.row.note }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button
                      v-if="!isReadOnly"
                      type="text"
                      size="small"
                      @click="edit_equipment(scope.row, scope.$index)"
                    >
                      <div v-if="showList[scope.$index].isShow === true">保存</div>
                      <div v-else>编辑</div>
                    </el-button>
                    <el-button
                      v-if="!isReadOnly"
                      type="text"
                      style="color: red"
                      size="small"
                      @click="delete_equipment(scope.row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </div>
        </el-tab-pane>
        <!-- PCI -->
        <el-tab-pane
          :key="key_list[1]"
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('PCI')
          "
        >
          <span slot="label">
            <i :class="selectedBloodVessel.detailIndex != null ? 'icon-finished' : 'icon-warn'"></i>
            PCI
          </span>

          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label">采用的术式</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_jrzlqk_cyss"
                :value.sync="coronaryInterveneDetail.operationMethod"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">该部位介入是否成功</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_jrzlqk_bwjr"
                :value.sync="coronaryInterveneDetail.interventionSuccess"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">治疗后狭窄程度</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryInterveneDetail.postDegreeValue1"
                  size="mini"
                  unit="%"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">治疗后TIMI血流</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_jrzlqk_zlhTIMI"
                :value.sync="coronaryInterveneDetail.postTIMI"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">该部位植入支架数量</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_jrzlqk_zrzj"
                :value.sync="coronaryInterveneDetail.stentingAmount"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术中并发症</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_jrzlqk_szbfz"
                :value.sync="coronaryInterveneDetail.intraoperativeComplication"
              />
            </div>
          </div>

          <div class="button-panel"></div>
        </el-tab-pane>

        <!-- FFR -->
        <el-tab-pane
          :key="key_list[2]"
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('FFR')
          "
        >
          <span slot="label">
            <i :class="selectedBloodVessel.detailIndex != null ? 'icon-finished' : 'icon-warn'"></i>
            FFR
          </span>

          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label">术前FFR</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryFFRDetail.preFFR"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术后FFR</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryFFRDetail.postFFR"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前是否有跳跃</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_FFR_ty1"
                :value.sync="coronaryFFRDetail.preJump"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术后是否有跳跃</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_FFR_ty2"
                :value.sync="coronaryFFRDetail.postJump"
              />
            </div>
          </div>

          <div class="button-panel"></div>
        </el-tab-pane>

        <!-- IVUS -->
        <el-tab-pane
          :key="key_list[3]"
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('IVUS')
          "
        >
          <span slot="label">
            <i :class="selectedBloodVessel.detailIndex != null ? 'icon-finished' : 'icon-warn'"></i>
            IVUS
          </span>

          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label">术前IVUS所见</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_IVUS_IVUSsj1"
                :value.sync="coronaryIVUSDetail.preIVUS"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前管腔最小直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryIVUSDetail.preMinLumenDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前管腔最大直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryIVUSDetail.preMaxLumenDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前管腔最小面积（mm2）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryIVUSDetail.preMinLumenArea"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前EEM最小直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryIVUSDetail.preMinEEMDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前EEM最大直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryIVUSDetail.preMaxEEMDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前EEM面积（mm2）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryIVUSDetail.preEEMArea"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前斑块负荷（%）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryIVUSDetail.prePlaqueLoad"
                  size="mini"
                />
              </div>
            </div>

            <div class="after-IVUS">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label">术后IVUS所见</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/gmzy_IVUS_IVUSsj2"
                    :value.sync="coronaryIVUSDetail.postIVUS"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后管腔最小直径（mm）</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="coronaryIVUSDetail.postMinLumenDiameter"
                      size="mini"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后管腔最大直径（mm）</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="coronaryIVUSDetail.postMaxLumenDiameter"
                      size="mini"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后支架内/处理后最小面积（mm2）</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="coronaryIVUSDetail.postMinLumenArea"
                      size="mini"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后EEM最小直径（mm）</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="coronaryIVUSDetail.postMinEEMDiameter"
                      size="mini"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后EEM最大直径（mm）</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="coronaryIVUSDetail.postMaxEEMDiameter"
                      size="mini"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后EEM面积（mm2）</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="coronaryIVUSDetail.postEEMArea"
                      size="mini"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">术后斑块负荷（%）</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="number"
                      v-model="coronaryIVUSDetail.postPlaqueLoad"
                      size="mini"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="button-panel"></div>
        </el-tab-pane>

        <!-- OCT -->
        <el-tab-pane
          :key="key_list[4]"
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('OCT')
          "
        >
          <span slot="label">
            <i :class="selectedBloodVessel.detailIndex != null ? 'icon-finished' : 'icon-warn'"></i>
            OCT
          </span>

          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label">术前OCT所见</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_OCT_OCTsj1"
                :value.sync="coronaryOCTDetail.preOCT"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前管腔最小直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryOCTDetail.preMinLumenDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前管腔最大直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryOCTDetail.preMaxLumenDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前管腔最小面积（mm2）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryOCTDetail.preMinLumenArea"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前EEM最小直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryOCTDetail.preMinEEMDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前EEM最大直径（mm）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryOCTDetail.preMaxEEMDiameter"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前EEM面积（mm2）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryOCTDetail.preEEMArea"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">术前斑块负荷（%）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryOCTDetail.prePlaqueLoad"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">术后OCT所见</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/gmzy_OCT_OCTsj2"
                  :value.sync="coronaryOCTDetail.postOCT"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后管腔最小直径（mm）</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="coronaryOCTDetail.postMinLumenDiameter"
                    size="mini"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后管腔最大直径（mm）</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="coronaryOCTDetail.postMaxLumenDiameter"
                    size="mini"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后支架内/处理后最小面积（mm2）</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="coronaryOCTDetail.postMinLumenArea"
                    size="mini"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后EEM最小直径（mm）</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="coronaryOCTDetail.postMinEEMDiameter"
                    size="mini"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后EEM最大直径（mm）</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="coronaryOCTDetail.postMaxEEMDiameter"
                    size="mini"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后EEM面积（mm2）</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="coronaryOCTDetail.postEEMArea"
                    size="mini"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后斑块负荷（%）</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="coronaryOCTDetail.postPlaqueLoad"
                    size="mini"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="button-panel"></div>
        </el-tab-pane>

        <!-- 旋磨 -->
        <el-tab-pane
          :key="key_list[5]"
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('旋磨')
          "
        >
          <span slot="label">
            <i :class="selectedBloodVessel.detailIndex != null ? 'icon-finished' : 'icon-warn'"></i>
            旋磨
          </span>

          <el-table
            :data="coronaryROTADetail"
            class="inner-table"
            highlight-current-row
            :header-cell-style="{ padding: 0 }"
            :key="tableKey"
            @row-click="_rowROTAClick"
            height="200px"
            style="width: 99%"
            border
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="rotaSize" label="磨头大小"></el-table-column>
            <el-table-column prop="revolution" label="转数（万转）"></el-table-column>
            <el-table-column prop="immediateEffect" label="即刻效果"></el-table-column>
          </el-table>

          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label">磨头</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_xm_mtdx"
                :value.sync="coronaryROTADetailInfo.rotaSize"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">转数（单位：万转）</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryROTADetailInfo.revolution"
                  size="mini"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">即刻效果</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_xm_jkxg"
                :value.sync="coronaryROTADetailInfo.immediateEffect"
              />
            </div>
          </div>

          <div class="button-panel">
            <el-button
              type="success"
              class="common-button commonBtn"
              icon="el-icon-download"
              v-if="!isReadOnly"
              v-debounce="_addROTA"
            >
              保存
            </el-button>
            <el-button
              type="danger"
              class="common-button commonBtn"
              icon="el-icon-close"
              v-if="!isReadOnly"
              v-debounce="_deleteROTA"
            >
              删除
            </el-button>
          </div>
        </el-tab-pane>

        <!-- 抽栓 -->
        <el-tab-pane
          :key="key_list[6]"
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('抽栓')
          "
        >
          <span slot="label">
            <i :class="selectedBloodVessel.detailIndex != null ? 'icon-finished' : 'icon-warn'"></i>
            抽栓
          </span>

          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label">抽吸时间</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryThrombus.thrombusTime"
                  size="mini"
                  unit="s"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">抽出血栓量</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryThrombus.thrombusNumber"
                  size="mini"
                  unit="ml"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">抽吸次数</div>
              <div>
                <cqt-input
                  :isReadonly="isReadOnly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="number"
                  v-model="coronaryThrombus.thrombusCount"
                  size="mini"
                  unit="次"
                />
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">抽出血栓类型</div>
              <cqt-select
                :isReadonly="isReadOnly"
                activeUrl="/v1/webconsole/comboData/get/gmzy_thrombus_type"
                :value.sync="coronaryThrombus.thrombusType"
              />
            </div>
          </div>

          <div class="button-panel"></div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <CoronaryInfoInputDialog
      v-bind="$attrs"
      :formId="formId"
      ref="coronaryInfoInputDialog"
      @save="_dialogSave"
    ></CoronaryInfoInputDialog>
  </div>
</template>

<script>
import CoronaryInfoInputDialog from './CoronaryInfoInputDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'

export default {
  name: 'CoronaryInfoInput',
  components: {
    CoronaryInfoInputDialog,
    CqtInput,
    CqtSelect
  },
  model: {
    event: 'change',
    prop: 'allDetail'
  },
  props: {
    isReadOnly: {
      type: Boolean,
      require: true
    },
    is_add: {
      type: String,
      require: true
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      equipment_list: [],
      showList: [],
      selectedROTA: null,
      selectedBloodVessel: null,
      tableKey: null,
      tableData: [],
      equipmentInfo: [],
      key_list: [0, 1, 2, 3, 4, 5, 6],
      tabs_index: '0',
      // PCI接口
      coronaryInterveneDetail: {
        interventionSuccess: null,
        intraoperativeComplication: null,
        note: null,
        operationMethod: null,
        postDegreeValue1: 0,
        postDegreeValue2: 0,
        postTIMI: null,
        stentingAmount: null,
        valueType: null
      },
      // IVUS
      coronaryIVUSDetail: {
        postEEMArea: 0,
        postIVUS: null,
        postMaxEEMDiameter: 0,
        postMaxLumenDiameter: 0,
        postMinEEMDiameter: 0,
        postMinLumenArea: 0,
        postMinLumenDiameter: 0,
        postPlaqueLoad: 0,
        preEEMArea: 0,
        preIVUS: null,
        preMaxEEMDiameter: 0,
        preMaxLumenDiameter: 0,
        preMinEEMDiameter: 0,
        preMinLumenArea: 0,
        preMinLumenDiameter: 0,
        prePlaqueLoad: 0
      },

      // FFR
      coronaryFFRDetail: {
        postFFR: 0,
        postJump: null,
        preFFR: 0,
        preJump: null
      },
      // OCT
      coronaryOCTDetail: {
        postEEMArea: 0,
        postMaxEEMDiameter: 0,
        postMaxLumenDiameter: 0,
        postMinEEMDiameter: 0,
        postMinLumenArea: 0,
        postMinLumenDiameter: 0,
        postOCT: null,
        postPlaqueLoad: 0,
        preEEMArea: 0,
        preMaxEEMDiameter: 0,
        preMaxLumenDiameter: 0,
        preMinEEMDiameter: 0,
        preMinLumenArea: 0,
        preMinLumenDiameter: 0,
        preOCT: null,
        prePlaqueLoad: 0
      },
      // 旋磨
      coronaryROTADetail: [],
      coronaryROTADetailInfo: {
        immediateEffect: null,
        revolution: 0,
        rotaSize: null
      },

      // 抽栓
      coronaryThrombus: {
        thrombusCount: null,
        thrombusNumber: null,
        thrombusTime: 0,
        thrombusType: null
      }
    }
  },
  computed: {},
  watch: {
    selectedBloodVessel: {
      handler(val) {
        if (val) {
          if (val.coronaryEquipmentDetail) {
            this.equipment_list = val.coronaryEquipmentDetail
            this.showList = []
            if (this.equipment_list.length) {
              this.equipment_list.forEach(() => {
                this.showList.push({ isShow: false })
              })
            }
          }

          this.getAllSuggestions(val)
        }
      }
    },
    allDetail: {
      handler(val) {
        if (val) {
          this._getAllBloodVesselDetail()
          this.get_equipment_list()
        }
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 页码切换
    handleClick(tab) {
      this.tabs_index = tab.index
    },
    get_equipment_list() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.allEquipments.id}`
      let formData = new FormData()
      formData.append('elementName', this.allDetail.allEquipments.uiElementId)

      this.$api.post(url, formData).then(
        (res) => {
          if (res.data && res.data.data) {
            this.equipment_list = res.data.data
            this.showList = []
            this.equipment_list.forEach(() => {
              this.showList.push({ isShow: false })
            })
          }
        },
        (err) => console.log(err)
      )
    },
    //编辑耗材信息
    edit_equipment(row, index) {
      this.showList[index].isShow = !this.showList[index].isShow
      if (this.showList[index].isShow === false) {
        for (let i = 0; i < this.tableData.length; i++) {
          const element = this.tableData[i]
          if (element.coronaryEquipmentDetail?.length > 0) {
            const foundIndex = element.coronaryEquipmentDetail.findIndex(
              (detail) => detail.equipmentIndex === row.equipmentIndex
            )

            if (foundIndex !== -1) {
              element.coronaryEquipmentDetail.splice(foundIndex, 1, row)
            }
          }
        }
        return this.$message.success('修改成功')
      }
    },
    //根据detailIndex删除耗材
    delete_equipment(row) {
      for (let i = 0; i < this.tableData.length; i++) {
        const element = this.tableData[i]
        if (element.coronaryEquipmentDetail?.length > 0) {
          element.coronaryEquipmentDetail = element.coronaryEquipmentDetail.filter(
            (detail) => detail.equipmentIndex !== row.equipmentIndex
          )
        }
      }
      this.equipment_list = this.equipment_list.filter(
        (element) => element.equipmentIndex !== row.equipmentIndex
      )

      return this.$message.success('删除成功')
    },
    _getAllBloodVesselDetail() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.coronaryDetail.id}`
      let formData = new FormData()
      formData.append('elementName', 'coronaryDetail')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            console.log('tableData', res.data)
            this.tableData = res.data
            for (let i = 0; i < this.tableData.length; i++) {
              const element = this.tableData[i]
              let url = `/v1/webconsole/eform/extendfields/${this.formId}/${element.detailIndex}`
              let formData = new FormData()
              formData.append('elementName', 'coronaryEquipmentDetail')
              this.$api.post(url, formData).then(
                (res) => {
                  if (res.data && res.data.data) {
                    element.coronaryEquipmentDetail = res.data.data
                  } else {
                    element.coronaryEquipmentDetail = []
                  }
                },
                (err) => console.log(err)
              )
              if (element.handlingSuggestions) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },
    getAllSuggestions(val, type) {
      if (val.handlingSuggestions?.includes('PCI')) {
        this._getPCI(val, type)
      }
      if (val.handlingSuggestions?.includes('FFR')) {
        this._getFFR(val, type)
      }
      if (val.handlingSuggestions?.includes('IVUS')) {
        this._getIVUS(val, type)
      }
      if (val.handlingSuggestions?.includes('OCT')) {
        this._getOCT(val, type)
      }
      if (val.handlingSuggestions?.includes('旋磨')) {
        this._getROTA(val, type)
      }
      if (val.handlingSuggestions?.includes('抽栓')) {
        this._getThrombus(val, type)
      }
    },

    _insert() {
      if (this.is_add !== '有') return this.$message.warning('冠脉节段无明显狭窄,不可添加血管')
      this.$refs.coronaryInfoInputDialog.Show()
    },

    _modify() {
      if (!this.selectedBloodVessel) {
        return this.$message.warning('请先选择一支血管')
      }
      if (this.selectedBloodVessel) {
        this.$refs.coronaryInfoInputDialog.Show(this.selectedBloodVessel)
      }
    },
    _rowClick(row) {
      console.log(row)
      this.selectedBloodVessel = row
      this.tabs_index = '0'
      if (row.detailIndex) {
        this.key_list = [
          row.detailIndex,
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000)
        ]
      } else {
        this.key_list = [
          row.id,
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000)
        ]
      }
    },
    _rowROTAClick(row) {
      this.selectedROTA = row
      this.coronaryROTADetailInfo = { ...this.selectedROTA }
    },
    _doubleModify(oldBloodVessel) {
      if (this.isReadOnly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.selectedBloodVessel = oldBloodVessel
      this.$refs.coronaryInfoInputDialog.Show(this.selectedBloodVessel)
    },
    _dialogSave(val) {
      // 手动增加病变部位
      if (val.coronaryEquipmentDetail && val.coronaryEquipmentDetail.length > 0) {
        val.coronaryEquipmentDetail = val.coronaryEquipmentDetail.map((item) => ({
          ...item,
          lesionRegion: val.lesionRegion,
          equipmentIndex: item.equipmentId
        }))
      }

      this.selectedBloodVessel = null
      if (!val.detailIndex) {
        let isId = true
        for (let i = 0; i < this.tableData.length; i++) {
          const element = this.tableData[i]
          if (val.id && val.id === element.id) {
            isId = false
            this.tableData[i] = val
            this.$set(this.tableData, i, this.tableData[i])
          }
        }
        if (isId) {
          val.id = Math.random(0, 1000) + Math.random(0, 1000)
          this.tableData.push(val)
        }
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          const element = this.tableData[i]
          if (val.detailIndex === element.detailIndex) {
            this.tableData[i] = val
            this.$set(this.tableData, i, this.tableData[i])
          }
        }
      }
    },
    open_delete() {
      if (!this.selectedBloodVessel) return this.$message.warning('请先选择一支血管')
      this.$confirm('是否删除该血管信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._delete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _delete() {
      if (this.selectedBloodVessel) {
        if (this.selectedBloodVessel.detailIndex) {
          for (let i = 0; i < this.tableData.length; i++) {
            const element = this.tableData[i]
            if (this.selectedBloodVessel.detailIndex === element.detailIndex) {
              this.tableData.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.tableData.length; i++) {
            const element = this.tableData[i]
            if (this.selectedBloodVessel.id === element.id) {
              this.tableData.splice(i, 1)
              break
            }
          }
        }
        this.selectedBloodVessel = null
      }
    },

    //抽栓
    async _getThrombus(val, type) {
      if (type === 'init') {
        this.coronaryThrombus = {
          ...this.$options.data().coronaryThrombus
        }
        val.coronaryThrombus = this.coronaryThrombus
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'coronaryThrombus')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.coronaryThrombus = res.data.data[0]
                this.coronaryThrombus = val.coronaryThrombus
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.coronaryThrombus) {
          this.coronaryThrombus = val.coronaryThrombus
        } else {
          this.coronaryThrombus = {
            ...this.$options.data().coronaryThrombus
          }
          val.coronaryThrombus = this.coronaryThrombus
        }
      }
    },
    //PCI
    async _getPCI(val, type) {
      if (type === 'init') {
        this.coronaryInterveneDetail = {
          ...this.$options.data().coronaryInterveneDetail
        }
        val.coronaryInterveneDetail = this.coronaryInterveneDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'coronaryInterveneDetail')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.coronaryInterveneDetail = res.data.data[0]
                this.coronaryInterveneDetail = val.coronaryInterveneDetail
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.coronaryInterveneDetail) {
          this.coronaryInterveneDetail = val.coronaryInterveneDetail
        } else {
          this.coronaryInterveneDetail = {
            ...this.$options.data().coronaryInterveneDetail
          }
          val.coronaryInterveneDetail = this.coronaryInterveneDetail
        }
      }
    },

    //FFR
    async _getFFR(val, type) {
      if (type === 'init') {
        this.coronaryFFRDetail = {
          ...this.$options.data().coronaryFFRDetail
        }
        val.coronaryFFRDetail = this.coronaryFFRDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'coronaryFFRDetail')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.coronaryFFRDetail = res.data.data[0]
                this.coronaryFFRDetail = val.coronaryFFRDetail
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.coronaryFFRDetail) {
          this.coronaryFFRDetail = val.coronaryFFRDetail
        } else {
          this.coronaryFFRDetail = {
            ...this.$options.data().coronaryFFRDetail
          }
          val.coronaryFFRDetail = this.coronaryFFRDetail
        }
      }
    },

    //IVUS
    async _getIVUS(val, type) {
      if (type === 'init') {
        this.coronaryIVUSDetail = {
          ...this.$options.data().coronaryIVUSDetail
        }
        val.coronaryIVUSDetail = this.coronaryIVUSDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'coronaryIVUSDetail')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.coronaryIVUSDetail = res.data.data[0]
                this.coronaryIVUSDetail = val.coronaryIVUSDetail
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.coronaryIVUSDetail) {
          this.coronaryIVUSDetail = val.coronaryIVUSDetail
        } else {
          this.coronaryIVUSDetail = {
            ...this.$options.data().coronaryIVUSDetail
          }
          val.coronaryIVUSDetail = this.coronaryIVUSDetail
        }
      }
    },

    //OCT
    async _getOCT(val, type) {
      if (type === 'init') {
        this.coronaryOCTDetail = {
          ...this.$options.data().coronaryOCTDetail
        }
        val.coronaryFFRDetail = this.coronaryOCTDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'coronaryOCTDetail')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.coronaryOCTDetail = res.data.data[0]
                this.coronaryOCTDetail = val.coronaryOCTDetail
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.coronaryOCTDetail) {
          this.coronaryOCTDetail = val.coronaryOCTDetail
        } else {
          this.coronaryOCTDetail = {
            ...this.$options.data().coronaryOCTDetail
          }
          val.coronaryOCTDetail = this.coronaryOCTDetail
        }
      }
    },

    //ROTA
    _addROTA() {
      if (this.selectedROTA?.detailIndex) {
        for (let i = 0; i < this.coronaryROTADetail.length; i++) {
          const element = this.coronaryROTADetail[i]
          if (this.selectedROTA.detailIndex === element.detailIndex) {
            this.$set(this.coronaryROTADetail, i, this.coronaryROTADetailInfo)
            break // 可以添加 break 来提升性能
          }
        }
      } else {
        this.coronaryROTADetailInfo.detailIndex = Math.random(0, 1000) + Math.random(0, 1000)
        this.coronaryROTADetail.push(this.coronaryROTADetailInfo)
      }
      this.coronaryROTADetailInfo = {
        ...this.$options.data().coronaryROTADetailInfo
      }
      this.selectedROTA = null
    },
    _deleteROTA() {
      if (this.selectedROTA) {
        this.coronaryROTADetail = this.coronaryROTADetail.filter(
          (e) => e.detailIndex !== this.selectedROTA.detailIndex
        )
        this.selectedBloodVessel.coronaryROTADetail = this.coronaryROTADetail
        this.selectedROTA = null
      }
    },
    async _getROTA(val, type) {
      if (type === 'init') {
        this.coronaryROTADetail = []
        this.coronaryROTADetailInfo = {
          ...this.$options.data().coronaryROTADetailInfo
        } // 初始化清空
        val.coronaryROTADetail = this.coronaryROTADetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'coronaryROTADetail')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                for (let i = 0; i < res.data.data.length; i++) {
                  const element = res.data.data[i]
                  element.detailIndex = Math.random(0, 1000) + Math.random(0, 1000)
                }
                val.coronaryROTADetail = res.data.data
                this.coronaryROTADetail = val.coronaryROTADetail
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.coronaryROTADetail) {
          this.coronaryROTADetail = val.coronaryROTADetail
        } else {
          this.coronaryROTADetail = this.$options.data().coronaryROTADetail
          val.coronaryROTADetail = this.coronaryROTADetail
        }
      }
    }
  }
}
</script>
