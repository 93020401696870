<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.item-row > span {
  margin: 0 15px;
}

.input_has_unit {
  width: 180px;
}

.dialogContent::-webkit-scrollbar {
  width: 12px;
}

.dialogContent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.dialogContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.dialogContent::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.dialogContent {
  flex: 1;
  overflow: auto;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="血管信息编辑"
    v-show="isShow"
    @close="Close"
  >
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialogContent">
      <div class="top-item-row">
        <div class="item-row">
          <span>病变部位</span>
          <cqt-select
            activeUrl="/v1/webconsole/comboData/get/gmzy_xgxxlr_bbbw"
            :expand="false"
            :value.sync="coronaryDetail.lesionRegion"
          />
        </div>

        <div class="item-row">
          <span>狭窄程度(%)</span>
          <cqt-input
            class="input_has_unit"
            placeholder="请输入"
            type="number"
            v-model="coronaryDetail.degreeValue"
            size="mini"
          />
        </div>

        <div class="item-row">
          <span>病变长度(mm)</span>
          <cqt-input
            class="input_has_unit"
            placeholder="请输入"
            type="number"
            v-model="coronaryDetail.lengthValue"
            @change="change_lesionFeature"
            size="mini"
          />
        </div>

        <div class="item-row">
          <span>病变特点</span>
          <cqt-select
            activeUrl="/v1/webconsole/comboData/get/gmzy_xgxxlr_bbtd"
            :expand="false"
            :value.sync="coronaryDetail.lesionFeature"
          />
        </div>
        <div class="item-row">
          <span>TIMI血流</span>
          <cqt-select
            activeUrl="/v1/webconsole/comboData/get/gmzy_jrzlqk_zlhTIMI"
            :expand="false"
            :value.sync="coronaryDetail.timi"
          />
        </div>
      </div>
      <div class="top-item-row">
        <div class="item-row">
          <span>病变分型</span>
          <cqt-select
            activeUrl="/v1/webconsole/comboData/get/gmzy_xgxxlr_bbfx"
            :expand="false"
            :value.sync="coronaryDetail.lesionKind"
          />
        </div>

        <div class="item-row">
          <span>既往是否有介入治疗史</span>
          <cqt-select
            activeUrl="/v1/webconsole/comboData/get/gmzy_xgxxlr_jrzl"
            :expand="false"
            :value.sync="coronaryDetail.interventionHistory"
          />
        </div>
        <div class="item-row">
          <span>既往支架/药球植入情况</span>
          <cqt-select
            activeUrl="/v1/webconsole/comboData/get/gmzy_xgxxlr_jwzj"
            :expand="false"
            :value.sync="coronaryDetail.stentingHistory"
          />
        </div>
        <div class="item-row">
          <span>既往支架/药球类型</span>
          <cqt-select
            activeUrl="/v1/webconsole/comboData/get/gmzy_xgxxlr_jwzjlx"
            :expand="false"
            :value.sync="coronaryDetail.stentingType"
          />
        </div>

        <div class="item-row"></div>
      </div>
      <div class="top-item-row">
        <el-col :span="14">
          <cqt-radio :value.sync="handlingSuggestions">
            <el-radio label="择期PCI">择期PCI</el-radio>
            <el-radio label="择期CABG">择期CABG</el-radio>
            <el-radio label="择期杂交手术">择期杂交手术</el-radio>
            <el-radio label="药物治疗">药物治疗</el-radio>
            <el-radio label="其他治疗">其他治疗</el-radio>
            <el-radio label="本次治疗">本次治疗</el-radio>
          </cqt-radio>
        </el-col>

        <el-col :span="10">
          <current-checkbox-select
            v-if="handlingSuggestions === '本次治疗'"
            v-model="currentTreatment"
            :currentTreatment="this.currentTreatment"
          ></current-checkbox-select>
          <div v-if="handlingSuggestions === '药物治疗'" style="display: flex; align-items: center">
            <span>药物名称：</span>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/gmzy_xgxxlr_drug"
              :value.sync="coronaryDetail.drugName"
            />
          </div>
        </el-col>
      </div>
      <el-tag style="margin-left: 10px">介入耗材</el-tag>
      <div class="top-item-row">
        <el-table
          class="report-table"
          :data="tableData"
          ref="multipleTable"
          :header-cell-style="{ padding: 0 }"
          height="220px"
          highlight-current-row
          @row-click="_rowClick"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="index" align="center" width="50"></el-table-column>
          <el-table-column type="selection" align="center" width="45px"></el-table-column>
          <el-table-column
            property="surgeryDate"
            align="center"
            label="手术日期"
            show-overflow-tooltip
            width="180"
          ></el-table-column>
          <el-table-column
            property="surgeryBillNo"
            align="center"
            label="手术单号"
            show-overflow-tooltip
            width="120"
          ></el-table-column>
          <el-table-column
            property="equipmentName"
            align="center"
            label="自定义耗材名称"
            show-overflow-tooltip
            width="220"
          ></el-table-column>
          <el-table-column
            property="equipmentName"
            align="center"
            label="耗材名称"
            show-overflow-tooltip
            width="220"
          ></el-table-column>
          <el-table-column
            property="equipmentType"
            align="center"
            label="耗材规格"
            show-overflow-tooltip
            width="120"
          ></el-table-column>
          <el-table-column
            property="productLocation"
            align="center"
            label="生产厂家"
            show-overflow-tooltip
            width="120"
          ></el-table-column>
          <el-table-column
            property="expiryDate"
            align="center"
            label="有效期"
            show-overflow-tooltip
            width="120"
          ></el-table-column>
          <el-table-column
            property="price"
            align="center"
            label="耗材价格"
            show-overflow-tooltip
            width="120"
          ></el-table-column>
          <el-table-column
            property="number"
            align="center"
            label="数量"
            show-overflow-tooltip
            width="60"
          ></el-table-column>
          <el-table-column
            property="cost"
            align="center"
            label="价格"
            show-overflow-tooltip
            width="60"
          ></el-table-column>
          <el-table-column
            property="applyDepotName"
            align="center"
            label="申请科室"
            show-overflow-tooltip
            width="80"
          ></el-table-column>
          <el-table-column
            property="brand"
            align="center"
            label="供应商名称"
            show-overflow-tooltip
            width="120"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
// import CmSelect from '../../../../components/CmSelect.vue'
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

export default {
  name: 'CoronaryInfoInputDialog',
  components: {
    // CmSelect,
    CmDialogBorder,
    CurrentCheckboxSelect,
    CqtInput,
    CqtSelect,
    CqtRadio
  },

  props: {
    formId: {
      type: String,
      require: true
    },
    initDefaultData: {
      // 初始化数据
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      handlingSuggestions: '',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: '',
      coronaryDetail: {
        degreeValue: 0,
        handlingSuggestions: '',
        interventionHistory: null,
        lengthValue: 0,
        lesionFeature: null,
        lesionKind: null,
        lesionRegion: null,
        stentingHistory: null,
        stentingType: null,
        drugName: null,
        coronaryDetail: null,
        timi: null,
        coronaryEquipmentDetail: null
      },
      tableData: [],
      selectRowList: [],
      operationId: undefined
    }
  },
  mounted() {
    this.operationId = this.$route.query.operationId
  },
  computed: {},
  watch: {
    handlingSuggestions: {
      handler(val) {
        this.coronaryDetail.handlingSuggestions = val
        if (val !== '药物治疗') {
          this.coronaryDetail.drugName = ''
        }
      }
    },
    currentTreatment: {
      handler(val) {
        this.coronaryDetail.handlingSuggestions = '本次治疗'
        if (val && val.length > 0) {
          this.coronaryDetail.handlingSuggestions = ['本次治疗', ...val.split('|')].join('+')
        }
      }
    },
    initDefaultData: {
      handler(val) {
        if (val) {
          for (let i in val) {
            for (let j in this.coronaryDetail) {
              if (j === i) {
                this.coronaryDetail[i] = val[i]
              }
            }
          }
          console.log(this.coronaryDetail)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    Show(bloodVessel) {
      this.handlingSuggestions = ''
      this.$refs.multipleTable.clearSelection()
      if (bloodVessel) {
        this.coronaryDetail = deepClone(bloodVessel)
      } else if (!this.initDefaultData) {
        this.coronaryDetail = {
          degreeValue: 0,
          handlingSuggestions: '',
          interventionHistory: null,
          lengthValue: 0,
          lesionFeature: null,
          lesionKind: null,
          lesionRegion: null,
          stentingHistory: null,
          stentingType: null,
          drugName: null,
          timi: null
        }
      }
      if (!this.coronaryDetail.lesionRegion) {
        if (JSON.parse(localStorage.getItem('imgLabel'))) {
          this.coronaryDetail.lesionRegion = JSON.parse(localStorage.getItem('imgLabel'))
        }
      }
      let sugs = this.coronaryDetail.handlingSuggestions?.split('+') || []
      this.handlingSuggestions = sugs[0] || ''
      if (sugs.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }
      this.isShow = true
      this.initData()
    },
    Close() {
      this.isShow = false
    },

    async Save() {
      if (this.coronaryDetail.lesionRegion === null || this.coronaryDetail.lesionRegion === '') {
        return this.$message.warning('病变部位不能为空')
      }
      var idx = this.coronaryDetail.handlingSuggestions?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.coronaryDetail.handlingSuggestions.substr(0, idx + 1)
        var b = this.coronaryDetail.handlingSuggestions.substr(idx + 2)
        this.coronaryDetail.handlingSuggestions = a + b
      }
      this.$emit('save', this.coronaryDetail)
      this.Close()
      if (JSON.parse(localStorage.getItem('imgLabel'))) {
        localStorage.removeItem('imgLabel')
      }
      if (this.coronaryDetail.detailIndex) {
        return this.$message.success('修改成功')
      } else {
        return this.$message.success('保存成功')
      }
    },
    change_lesionFeature(val) {
      if (val <= 10) this.coronaryDetail.lesionFeature = '局限性病变'
      else if (val <= 20) this.coronaryDetail.lesionFeature = '管状病变'
      else if (val > 20) this.coronaryDetail.lesionFeature = '弥漫性病变'
    },
    _rowClick(row) {
      if (row) {
        this.$refs.multipleTable.toggleRowSelection(row)
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.selectRowList = val
      if (this.selectRowList.length > 0) {
        for (let i = 0; i < this.selectRowList.length; i++) {
          const ele = this.selectRowList[i]
          ele.useSuccess = '成功'
          if (!ele.num) {
            ele.num = 1
          }
          // if (ele.equipmentType.includes('预扩球囊')) {
          //   for (let j in this.allInfo.ykqn) {
          //     ele[j] = this.allInfo.ykqn[j]
          //   }
          // }
          // if (ele.equipmentType.includes('支架')) {
          //   for (let j in this.allInfo.zj) {
          //     ele[j] = this.allInfo.zj[j]
          //   }
          // }
          // if (ele.equipmentType.includes('药物球囊')) {
          //   for (let j in this.allInfo.ywqn) {
          //     ele[j] = this.allInfo.ywqn[j]
          //   }
          // }
          // if (ele.equipmentType.includes('后扩球囊')) {
          //   for (let j in this.allInfo.hkqn) {
          //     ele[j] = this.allInfo.hkqn[j]
          //   }
          // }
          // if (ele.equipmentType.includes('指引导管')) {
          //   for (let j in this.allInfo.zydg) {
          //     ele[j] = this.allInfo.zydg[j]
          //   }
          // }
          // if (ele.equipmentType.includes('导丝')) {
          //   for (let j in this.allInfo.ds) {
          //     ele[j] = this.allInfo.ds[j]
          //   }
          // }
          // if (ele.equipmentType.includes('抽栓导管')) {
          //   for (let j in this.allInfo.csdg) {
          //     ele[j] = this.allInfo.csdg[j]
          //   }
          // }
          // if (ele.equipmentType.includes('微导管')) {
          //   for (let j in this.allInfo.wdg) {
          //     ele[j] = this.allInfo.wdg[j]
          //   }
          // }
        }
      }
      this.coronaryDetail.coronaryEquipmentDetail = deepClone(this.selectRowList)
    },
    initData() {
      // 读耗材
      if (!this.operationId) {
        return this.$message({
          message: '手术id不存在',
          type: 'warning'
        })
      }
      this.$api.get(`/v1/webconsole/equipment/operation/query/${this.operationId}`).then((res) => {
        if (res.data && res.data.data) {
          this.tableData = res.data.data
        } else {
          this.tableData = []
        }
        if (this.coronaryDetail.coronaryEquipmentDetail?.length > 0) {
          for (let i = 0; i < this.tableData.length; i++) {
            const ele = this.tableData[i]
            for (let j = 0; j < this.coronaryDetail.coronaryEquipmentDetail.length; j++) {
              const element = this.coronaryDetail.coronaryEquipmentDetail[j]
              if (ele.equipmentIndex === element.equipmentIndex) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(ele)
                })
              }
            }
          }
        }
      })
    }
  }
}
</script>
