<template>
  <el-checkbox-group v-model="valueList" style="flex: 1">
    <el-checkbox v-for="(item, index) in tableList" :key="index" :label="item.value">
      {{ item.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  data() {
    return {
      innerValue: '',
      valueList: [],
      tableList: [
        {
          value: 'PCI',
          label: 'PCI'
        },
        {
          value: 'FFR',
          label: 'FFR'
        },
        {
          value: 'IVUS',
          label: 'IVUS'
        },
        {
          value: 'OCT',
          label: 'OCT'
        },
        {
          value: '旋磨',
          label: '旋磨'
        },
        {
          value: '抽栓',
          label: '抽栓'
        }
      ]
    }
  },

  model: {
    prop: 'value',
    event: 'change'
  },
  props: ['currentTreatment'],
  mounted() {
    var a = this.currentTreatment.split('|')
    this.valueList = a
  },
  watch: {
    valueList: {
      handler(val) {
        if (val) {
          this.$emit('change', val.join('|'))
        }
      }
    },

    value: {
      handler(val) {
        if (typeof val === 'number') val += ''
        this.innerValue = val
        if (val) {
          this.valueList = val.split('|')
        }
      },
      immediate: true
    },
    innerValue: {
      handler(val) {
        this.$emit('change', val)
      },
      immediate: true
    }
  }
}
</script>
